import axios, {
  AxiosRequestConfig, AxiosInstance, AxiosResponse, AxiosError,
} from 'axios';
import { camelizeKeys, decamelizeKeys } from 'humps';
import { AccessToken } from '../models/security';
import { tenantiResponseError } from '../models/errors/TenantiResponseError';
import { emptyStringToNullValues } from '../formatters/emptyValueFormatter';

const httpClient: AxiosInstance = axios.create();

httpClient.interceptors.request.use((axiosConfig: AxiosRequestConfig) => {
  const config = axiosConfig;
  const refreshToken = localStorage.getItem('refreshToken');
  const accessToken: AccessToken = new AccessToken();

  config.baseURL = process.env.REACT_APP_API_BASE_URL;
  config.headers.Authorization = `Bearer ${accessToken.getToken()}`;

  if (accessToken.isExpired() || (accessToken.isValid() && !accessToken.hasRole())) {
    axios.post('/auth/refresh', null, {
      headers: {
        Authorization: `Bearer ${refreshToken}`,
      },
      baseURL: config.baseURL,
    }).then((response) => {
      localStorage.setItem('accessToken', response.data.access_token);
      localStorage.setItem('refreshToken', response.data.refresh_token);
    }).catch(() => {
      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');
      window.location.replace('/auth/login');
    });
  }

  if (config.data && !(config.data instanceof FormData)) {
    config.data = decamelizeKeys(emptyStringToNullValues(config.data));
  }

  return config;
});

httpClient.interceptors.response.use((response: AxiosResponse) => {
  if (response.data && response.headers['content-type'] === 'application/json') {
    response.data = camelizeKeys(response.data);
  }
  return response;
}, (axiosError: AxiosError) => {
  const error = axiosError;

  if (error.response?.data && error.response.headers['content-type'] === 'application/json') {
    error.response.data = camelizeKeys(error.response.data);
  }

  if (error.response && error.response.status === 422) {
    return Promise.reject(error);
  }

  const errorBody = {
    name: error.response?.data.name,
    statusCode: error.response?.status,
    message: error.response?.data.error,
  };

  return tenantiResponseError.resolveResponseError(errorBody)
    .catch((responseError) => Promise.reject(responseError));
});

export default httpClient;
