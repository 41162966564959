import { UseQueryResult, useQuery } from 'react-query';
import httpClient from '../../core/httpClient';
import { QUERY_OPTIONS } from '../../constants';
import { YardiFilesEntity } from './types';

export const useGetAllYardiFiles = (
  enabled?: boolean,
): UseQueryResult<YardiFilesEntity[], Error> => useQuery<YardiFilesEntity[], Error>(
  ['yardi-files'],
  async () => {
    const response = await httpClient.get('/yardi');
    return response.data;
  },
  {
    enabled,
    ...QUERY_OPTIONS,
  },
);
