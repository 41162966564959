import { useMutation, UseMutationResult, useQueryClient } from 'react-query';
import httpClient from '../../core/httpClient';
import {
  CreateTenantMutationVariables,
  DeleteTenantMutationVariables,
  DeleteDataPointsMutationVariables,
  UpdateTenantMutationVariables,
} from './types';
import { Tenant } from '../../types/tenant';
import { TenantListing } from '../../types/buildings';

export const useCreateTenantMutation = (): UseMutationResult<Tenant, Error, CreateTenantMutationVariables> => {
  const queryClient = useQueryClient();

  return useMutation<Tenant, Error, CreateTenantMutationVariables>(
    async ({ request }) => {
      const response = await httpClient.post('/companies', request);
      return response.data;
    },
    {
      onSuccess: async (data) => {
        const prevTenantData = queryClient.getQueryData<Tenant[]>(['tenants']);
        if (prevTenantData) {
          const newData = [data, ...prevTenantData];
          // sort by name
          newData.sort((a, b) => a.name.localeCompare(b.name));
          queryClient.setQueryData(['tenants'], newData);
        }
        const dataList: TenantListing = {
          id: data.id,
          companyName: data.name,
          employeesCountChange: null,
          employeesCount: null,
          totalArea: 0,
          leaseEndDate: '',
        };
        const prevData = queryClient.getQueryData<TenantListing[]>(['company-aggregated-data', '', '', '', 0]);
        if (prevData) {
          const newData = [dataList, ...prevData];
          // sort by name
          newData.sort((a, b) => a.companyName.localeCompare(b.companyName));
          queryClient.setQueryData(['company-aggregated-data', '', '', '', 0], newData);
        }
      },
    },
  );
};

export const useUpdateTenantMutation = (): UseMutationResult<Tenant, Error, UpdateTenantMutationVariables> => {
  const queryClient = useQueryClient();

  return useMutation<Tenant, Error, UpdateTenantMutationVariables>(
    async ({ request, tenantId }) => {
      const response = await httpClient.put(`/companies/${tenantId}`, request);
      return response.data;
    },
    {
      onSuccess: async (data, variables) => {
        queryClient.setQueryData(['tenants', variables.tenantId], data);
        const prevData = queryClient.getQueryData<TenantListing[]>(['company-aggregated-data', '', '', '', 0]);
        if (prevData) {
          const newData = prevData.map((tenant) => {
            if (tenant.id === data.id) {
              return {
                ...data,
                companyName: data.name,
              };
            }
            return tenant;
          });
          queryClient.setQueryData(['company-aggregated-data', '', '', '', 0], newData);
        }
      },
    },
  );
};

export const useDeleteTenant = (): UseMutationResult<null, Error, DeleteTenantMutationVariables> => {
  const queryClient = useQueryClient();

  return useMutation<null, Error, DeleteTenantMutationVariables>(
    async ({ companyId }) => httpClient.delete(`/companies/${companyId}`),
    {
      onSuccess: async (data, variables) => {
        const prevData = queryClient.getQueryData<TenantListing[]>(['company-aggregated-data', '', '', '', 0]);
        if (prevData) {
          queryClient.setQueryData(['company-aggregated-data', '', '', '', 0],
            prevData.filter((tenant) => tenant.id !== variables.companyId));
        }
      },
    },
  );
};

export const useDeleteDataPoint = (): UseMutationResult<null, Error,
DeleteDataPointsMutationVariables> => {
  const queryClient = useQueryClient();

  return useMutation<null, Error, DeleteDataPointsMutationVariables>(
    async ({ statisticsId }) => httpClient.delete(`/companies_statistics/${statisticsId}`),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries('dataPoints');
      },
    },
  );
};
