import React, {
  ReactElement, useContext, useState,
} from 'react';
import {
  Box, Button, FormControl, IconButton, InputLabel, Select, Tooltip, Typography,
} from '@material-ui/core';
import { Delete, Edit } from '@material-ui/icons';
import { Link, useHistory } from 'react-router-dom';
import { MaterialReactTable, MRT_ColumnDef } from 'material-react-table';
import { useGetAllBuildingsByStatusQuery } from '../../../queries/buildings';
import { useDeleteBuildingMutation } from '../../../queries/buildings/mutations';
import PageTitle from '../../ui/PageTitle';
import { formatAreaSize } from '../../../formatters/areaSizeFormatter';
import { Building, BuildingStatus } from '../../../types/buildings';
import useDeleteModal from '../../modals/DeleteModal/hooks';
import LoaderModal from '../../modals/LoaderModal/LoaderModal';
import { useNavigationManager } from '../../../navigation/navigationManager';
import { sortAreaColumn } from '../../../helpers/sortArea';
import { useButtonsStyles } from '../../../styles/useButtonsStyles';
import { accessToken } from '../../../models/security';
import { Store } from '../../../store/AppGlobalContext';
import { storeActions } from '../../../store/actions';
import { useListingsFiltrationStyles } from '../../../styles/useListingsFiltrationStyles';

const getFullAddress = (building: Building) => `
  ${building.streetAddress ? (`${building.streetAddress}, `) : ''}
  ${building.city ? (`${building.city}, `) : ''}
  ${building.state || ''}
  ${building.postalCode || ''}`;

const ListBuildings: React.FC = (): ReactElement => {
  const deleteBuildingMutation = useDeleteBuildingMutation();
  const { state, dispatch } = useContext(Store);
  const history = useHistory();
  const [buildingStatus, setBuildingStatus] = useState<BuildingStatus>(BuildingStatus.Active);
  const buildingStatuses = Object.keys(BuildingStatus);
  const {
    data: buildings, isLoading, isFetching,
  } = useGetAllBuildingsByStatusQuery(buildingStatus);

  const { buildingsNavigator } = useNavigationManager();

  const { showModal, RenderModal, modalId } = useDeleteModal();

  const buttonClasses = useButtonsStyles();
  const filterClasses = useListingsFiltrationStyles();

  const handleChange = (event: React.ChangeEvent<{ name?: string | undefined; value: unknown; }>) => {
    setBuildingStatus(event.target.value as BuildingStatus);
  };

  const deleteBuilding = (buildingId: number | null) => {
    if (!buildingId) {
      return;
    }
    deleteBuildingMutation.mutate({ buildingId, status: buildingStatus });
  };

  const navigateToEditBuilding = (id: number, event: React.MouseEvent) => {
    event.stopPropagation();
    buildingsNavigator.edit(Number(id)).navigate();
  };

  const navigateToViewSpaces = (id: number, event: React.MouseEvent) => {
    event.stopPropagation();
    history.push(`/buildings/${id}/spaces`);
  };

  const columns: MRT_ColumnDef<Building>[] = [
    {
      header: 'Building Name',
      accessorKey: 'name',
      // eslint-disable-next-line react/display-name
      accessorFn: (row) => (
        <Tooltip title={row.name || ''} placement="top" arrow>
          <Typography
            style={{ cursor: 'pointer' }}
            noWrap
          >
            {row.name || ''}
          </Typography>
        </Tooltip>
      ),
      enableSorting: false,
    },
    {
      header: 'Building Full Address',
      accessorKey: 'address',
      // eslint-disable-next-line react/display-name
      accessorFn: (row) => (
        <Tooltip title={getFullAddress(row) || ''} placement="top" arrow>
          <Typography
            style={{ cursor: 'pointer' }}
            noWrap
          >
            {getFullAddress(row) || ''}
          </Typography>
        </Tooltip>
      ),
      enableSorting: false,
    },
    {
      header: 'Total Area',
      accessorKey: 'totalArea',
      // eslint-disable-next-line react/display-name
      accessorFn: (row) => formatAreaSize(row.totalArea),
      enableSorting: true,
      enableMultiSort: true,
      sortingFn: (rowA, rowB) => sortAreaColumn(rowA.original.totalArea, rowB.original.totalArea),
      size: 50,
    },
    {
      header: 'Floors',
      accessorKey: 'floorsCount',
      enableSorting: false,
      size: 50,
    },
    {
      header: 'Actions',
      accessorKey: 'id',
      // eslint-disable-next-line react/display-name
      accessorFn: (row) => (
        <>
          <Button
            variant="outlined"
            size="small"
            color="primary"
            onClick={
              (e) => navigateToViewSpaces(row.id, e)
            }
          >
            View Spaces
          </Button>
          {!accessToken.isCurrentRoleBroker()
            && (
              <>
                <IconButton
                  style={{ width: '25px' }}
                  onClick={
                    (e) => navigateToEditBuilding(row.id, e)
                  }
                  aria-label="edit"
                >
                  <Edit />
                </IconButton>
                <IconButton
                  style={{ width: '25px' }}
                  aria-label="delete"
                  onClick={(e) => {
                    e.stopPropagation();
                    showModal(Number(row.id));
                  }}
                >
                  <Delete />
                </IconButton>
              </>
            )}
        </>
      ),
      enableResizing: false,
      enableSorting: false,
      size: 200,
    },
  ];

  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="flex-end" mb={3}>
        <PageTitle>Buildings</PageTitle>
        <Box display="flex" alignItems="center" alignSelf="flex-end" className={filterClasses.listingFilterWrapper}>
          <Box mr={1} width={184}>
            <FormControl
              style={{ minWidth: 184 }}
              margin="none"
              fullWidth
            >
              <InputLabel shrink htmlFor="building-status">Filter by Building Status</InputLabel>
              <Select
                native
                value={buildingStatus}
                onChange={handleChange}
                label="Filter by Building Status"
                inputProps={{
                  name: 'building-status',
                  id: 'building-status',
                }}
              >
                <option value="">All</option>
                {buildingStatuses?.map((status: string) => (
                  <option key={status} value={status}>
                    {status}
                  </option>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Box ml={1} minWidth={15}>
            {!accessToken.isCurrentRoleBroker()
              && (
              <Button
                component={Link}
                className={buttonClasses.large}
                to={buildingsNavigator.create().path}
                variant="contained"
                color="primary"
                fullWidth
              >
                Create Building
              </Button>
              )}
          </Box>
        </Box>
      </Box>
      <MaterialReactTable
        columns={columns}
        data={buildings || []}
        enableTopToolbar={false}
        enableColumnResizing
        enableColumnActions={false}
        enableFullScreenToggle={false}
        enableRowOrdering={false}
        enableGlobalFilterModes={false}
        enableDensityToggle={false}
        state={{ isLoading: isLoading || isFetching }}
        layoutMode="grid"
        muiTableProps={{
          sx: {
            tableLayout: 'fixed',
            display: 'table',
          },
        }}
        muiTableBodyRowProps={({ row }) => ({
          onClick: (event) => {
            event.stopPropagation();
            buildingsNavigator.single(Number(row.original.id)).navigate();
            dispatch({
              type: storeActions.UPDATE_STACKING_PLAN,
              payload: {
                ...state.stackingPlan,
                filteredByTenant: null,
              },
            });
          },
          sx: {
            cursor: 'pointer',
          },
        })}
      />
      <RenderModal
        body="Are you sure you want to delete this Building?"
        confirmAction={() => deleteBuilding(modalId)}
        title="Delete Building"
      />
      <LoaderModal isOpen={deleteBuildingMutation.isLoading} />
    </>
  );
};

export default ListBuildings;
