import React from 'react';
import {
  CircularProgress, Dialog,
} from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { SimpleDialogProps } from '../interfaces';

const useStyles = makeStyles(() => createStyles({
  root: {
    overflowY: 'unset',
    padding: '15px',
    textAlign: 'center',
  },
  msg: {
    marginBottom: '10px',
  },
}));

const LoaderModal: React.FC<SimpleDialogProps> = (props:SimpleDialogProps) => {
  const { isOpen, msg } = props;
  const classes = useStyles();
  return (
    <Dialog aria-labelledby="simple-dialog-title" open={isOpen}>
      <div className={classes.root}>
        <div className={classes.msg}>{msg}</div>
        <CircularProgress />
      </div>
    </Dialog>
  );
};

export default LoaderModal;
