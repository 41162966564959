import React, {
  ReactElement, useState, useEffect, useRef,
} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Input } from '@material-ui/core';
import { SuggestionEntity } from '../../queries/gpt/types';
import { useGetSuggestions } from '../../queries/gpt';

const useStyles = makeStyles(() => ({
  container: {
    position: 'relative',
    width: '100%',
  },
  input: {
    fontSize: '16px',
    padding: '8px',
    border: '1px solid #ccc',
    width: '100%',
    height: '55px',
  },
  suggestionsContainer: {
    position: 'absolute',
    width: '100%',
    bottom: '100%', // Display suggestions above the text input
    backgroundColor: '#fff',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
    maxHeight: '100px', // Limit the maximum height with a scroll bar
    overflowY: 'auto', // Add a scroll bar when needed
    zIndex: 1,
  },
  suggestions: {
    display: 'flex',
    flexDirection: 'column',
  },
  suggestion: {
    padding: '5px',
    cursor: 'pointer',
    backgroundColor: '#f0f0f0',
    borderRadius: '5px',
    marginBottom: '5px',
  },
  highlighted: {
    backgroundColor: '#0078d4',
    color: '#fff',
  },
}));

interface AutoCompleteTextFieldProps {
  inputValue: string;
  setInputValue: (arg0: string) => void;
  selectedSuggestions: SuggestionEntity[];
  setSelectedSuggestions: (arg0: SuggestionEntity[]) => void;
  askGpt: () => void;
  setLeftSidebarOpen: (arg0: boolean) => void;
  isMobile: boolean;
}

const AutoCompleteTextField: React.FC<AutoCompleteTextFieldProps> = ({
  inputValue,
  setInputValue,
  selectedSuggestions,
  setSelectedSuggestions,
  askGpt,
  setLeftSidebarOpen,
  isMobile,
}: AutoCompleteTextFieldProps): ReactElement => {
  const classes = useStyles();
  const inputRef = useRef<HTMLInputElement | null>(null);
  const suggestionsContainerRef = useRef<HTMLDivElement | null>(null);
  const suggestionRefs = useRef<(HTMLDivElement | null)[]>([]);
  const [suggestions, setSuggestions] = useState<SuggestionEntity[]>([]);
  const [highlightedIndex, setHighlightedIndex] = useState<number | null>(null);
  const [cursorPosition, setCursorPosition] = useState<number | null>(null);
  const { data: suggestionArray } = useGetSuggestions();

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const cursorIndex = e.target.selectionStart;
    setCursorPosition(cursorIndex);
    if (cursorIndex !== null) {
      selectedSuggestions.forEach((suggestion) => {
        if (suggestion.start && suggestion.end) {
        // inside suggestion
          if (cursorIndex > suggestion.start && cursorIndex < suggestion.end) {
            const updatedSelectedSuggestions = selectedSuggestions.filter(
              (selectedSuggestion) => selectedSuggestion !== suggestion,
            );

            setSelectedSuggestions(updatedSelectedSuggestions);
          } else if (cursorIndex <= suggestion.start) {
            const adjustment = inputValue.length - value.length;
            // eslint-disable-next-line no-param-reassign
            suggestion.start -= adjustment;
            // eslint-disable-next-line no-param-reassign
            suggestion.end -= adjustment;
          }
        }
      });
      setInputValue(value);
      // Filter and set suggestions based on the current word
      const words = value.trim().toLowerCase().split(' ');
      let currentWord = '';
      for (let i = 0; i < words.length; i += 1) {
        if (cursorIndex >= currentWord.length && cursorIndex <= currentWord.length + words[i].length) {
          currentWord = words[i];
          break;
        }
        currentWord += `${words[i]} `; // Add space for word separation
      }
      if (!currentWord.trim()) {
        setSuggestions([]);
        return;
      }
      const filteredSuggestions = suggestionArray?.filter((entity) => entity.name.toLowerCase().startsWith(currentWord));
      if (filteredSuggestions) {
        setSuggestions(filteredSuggestions);
      }
    }
  };

  const handleSuggestionClick = (suggestion: SuggestionEntity) => {
    if (cursorPosition !== null) {
      const words = inputValue.split(' ');
      let currentWord = '';
      for (let i = 0; i < words.length; i += 1) {
        if (cursorPosition >= currentWord.length && cursorPosition <= currentWord.length + words[i].length) {
          currentWord = words[i];
          break;
        }
        currentWord += `${words[i]} `; // Add space for word separation
      }
      const wordToAdd = `'${suggestion.name}'`;
      // Replace the cursor word with the selected suggestion
      const startPos = cursorPosition - currentWord.length;
      const newString = inputValue.substring(0, startPos) + wordToAdd + inputValue.substring(cursorPosition);
      selectedSuggestions.forEach((selectedSuggestion) => {
        if (selectedSuggestion.start && selectedSuggestion.end && startPos <= selectedSuggestion.start) {
          // eslint-disable-next-line no-param-reassign
          selectedSuggestion.start += wordToAdd.length - currentWord.length;
          // eslint-disable-next-line no-param-reassign
          selectedSuggestion.end += wordToAdd.length - currentWord.length;
        }
      });

      const endPos = startPos + wordToAdd.length - 1;
      setSelectedSuggestions([...selectedSuggestions, {
        start: startPos, end: endPos, name: wordToAdd, type: suggestion.type,
      }]);
      setInputValue(newString);
      setSuggestions([]);
      setHighlightedIndex(null);
      inputRef.current?.setSelectionRange(endPos + 1, endPos + 1);
    }
  };

  const handleArrowKeyNavigation = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (suggestions.length > 0) {
      if (e.key === 'ArrowUp') {
        e.preventDefault();
        const newIndex = highlightedIndex === null || highlightedIndex === 0
          ? suggestions.length - 1
          : highlightedIndex - 1;
        setHighlightedIndex(newIndex);
      } else if (e.key === 'ArrowDown') {
        e.preventDefault();
        const newIndex = highlightedIndex === null || highlightedIndex === suggestions.length - 1
          ? 0
          : highlightedIndex + 1;
        setHighlightedIndex(newIndex);
      } else if (e.key === 'Escape') {
        setSuggestions([]);
        setHighlightedIndex(null);
      }
    }

    if (e.key === 'Enter' && highlightedIndex === null) {
      askGpt();
    }
  };
  useEffect(() => {
    // Add event listener for the "Tab" key press
    const handleTabKeyPress = (e: KeyboardEvent) => {
      if (e.key === 'Tab' && suggestions.length > 0 && highlightedIndex !== null) {
        e.preventDefault();
        handleSuggestionClick(suggestions[highlightedIndex]);
      }
    };

    window.addEventListener('keydown', handleTabKeyPress);

    return () => {
      // Remove the event listener when the component unmounts
      window.removeEventListener('keydown', handleTabKeyPress);
    };
  }, [suggestions, highlightedIndex]);

  useEffect(() => {
    // Calculate the scroll position based on the highlighted suggestion
    if (highlightedIndex !== null && suggestionsContainerRef.current) {
      const highlightedItem = suggestionRefs.current[highlightedIndex];
      if (highlightedItem) {
        const container = suggestionsContainerRef.current;
        const { offsetTop } = highlightedItem;
        const { scrollTop } = container;
        const containerHeight = container.clientHeight;

        if (offsetTop < scrollTop) {
          // Scroll up to make the highlighted item visible
          container.scrollTop = offsetTop;
        } else if (offsetTop + highlightedItem.clientHeight > scrollTop + containerHeight) {
          // Scroll down to make the highlighted item visible
          container.scrollTop = offsetTop + highlightedItem.clientHeight - containerHeight;
        }
      }
    }
  }, [highlightedIndex]);

  return (
    <div className={classes.container}>
      <Input
        className={classes.input}
        placeholder="Type something"
        value={inputValue}
        onChange={handleInputChange}
        inputRef={inputRef}
        onKeyDown={handleArrowKeyNavigation}
        onFocus={() => {
          if (isMobile) {
            setLeftSidebarOpen(false);
          }
        }}
      />
      {suggestions.length > 0 && (
        <div
          id="suggestions-container"
          className={classes.suggestionsContainer}
          ref={suggestionsContainerRef}
        >
          {suggestions.map((suggestion, index) => (
            // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
            <div
              key={`${suggestion.type}${suggestion.name}`}
              onClick={() => handleSuggestionClick(suggestion)}
              className={`${classes.suggestion} ${
                index === highlightedIndex ? classes.highlighted : ''
              }`}
              // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
              tabIndex={0} // Add tabIndex to make it focusable
              // eslint-disable-next-line no-return-assign
              ref={(ref) => (suggestionRefs.current[index] = ref)} // Store a reference to the div
            >
              {`${suggestion.name} - ${suggestion.type}`}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default AutoCompleteTextField;
