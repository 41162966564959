import React from 'react';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import Modal from '../Modal/Modal';
import { ModalProps } from '../interfaces';

export interface BuildingDiffData {
  building_name: string;
  building_area: number;
  type: string;
  new_floors: Array<{
    type: string;
    floorName: string;
    floorOrder: string;
    oldArea: number;
    newArea: number;
  }>;
  updated_floors: Array<{
    type: string;
    floorName: string;
    floorOrder: string;
    oldArea: number;
    newArea: number;
  }>;
  same_floors: Array<{
    type: string;
    floorName: string;
    floorOrder: string;
    oldArea: number;
    newArea: number;
  }>;
  new_units: Array<{
    type: string;
    unitName: string;
    oldFloorOrder: number;
    newFloorOrder: number;
    oldArea: number;
    newArea: number;
  }>;
  updated_units: Array<{
    type: string;
    unitName: string;
    oldFloorOrder: number;
    newFloorOrder: number;
    oldArea: number;
    newArea: number;
  }>;
  same_units: Array<{
    type: string;
    unitName: string;
    oldFloorOrder: number;
    newFloorOrder: number;
    oldArea: number;
    newArea: number;
  }>;
}

interface BuildingDiffModalProps extends Omit<ModalProps, 'body'> {
  diffData: BuildingDiffData;
}

const BuildingDiffModal = ({
  isOpen,
  handleClose,
  confirmAction,
  diffData,
}: BuildingDiffModalProps): JSX.Element => {
  const renderFloorChanges = () => (
    <Box mb={3}>
      <Typography variant="h5" gutterBottom>
        <strong>{`Building Name: ${diffData.building_name}`}</strong>
      </Typography>
      <Typography variant="h6" gutterBottom>
        <strong>{`Building Area: ${diffData.building_area}`}</strong>
      </Typography>
      <Typography variant="h6" gutterBottom>Floor Changes</Typography>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Floor Name</TableCell>
            <TableCell>Floor Order</TableCell>
            <TableCell align="right">Old Area</TableCell>
            <TableCell align="right">New Area</TableCell>
            <TableCell>Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {diffData.new_floors.map((floor) => (
            <TableRow key={floor.floorName} style={{ backgroundColor: '#e8f5e9' }}>
              <TableCell>{floor.floorName}</TableCell>
              <TableCell>{floor.floorOrder}</TableCell>
              <TableCell align="right">{floor.oldArea.toFixed(2)}</TableCell>
              <TableCell align="right">{floor.newArea.toFixed(2)}</TableCell>
              <TableCell>Added</TableCell>
            </TableRow>
          ))}
          {diffData.updated_floors.map((floor) => (
            <TableRow key={floor.floorName} style={{ backgroundColor: '#fff3e0' }}>
              <TableCell>{floor.floorName}</TableCell>
              <TableCell>{floor.floorOrder}</TableCell>
              <TableCell align="right">{floor.oldArea.toFixed(2)}</TableCell>
              <TableCell align="right">{floor.newArea.toFixed(2)}</TableCell>
              <TableCell>Updated</TableCell>
            </TableRow>
          ))}
          {diffData.same_floors.map((floor) => (
            <TableRow key={floor.floorName}>
              <TableCell>{floor.floorName}</TableCell>
              <TableCell>{floor.floorOrder}</TableCell>
              <TableCell align="right">{floor.oldArea.toFixed(2)}</TableCell>
              <TableCell align="right">{floor.newArea.toFixed(2)}</TableCell>
              <TableCell>Unchanged</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  );

  const renderUnitChanges = () => (
    <Box>
      <Typography variant="h6" gutterBottom>Unit Changes</Typography>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Unit Name</TableCell>
            <TableCell align="right">Old Floor Order</TableCell>
            <TableCell align="right">New Floor Order</TableCell>
            <TableCell align="right">Old Area</TableCell>
            <TableCell align="right">New Area</TableCell>
            <TableCell>Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {diffData.new_units.map((unit) => (
            <TableRow key={unit.unitName} style={{ backgroundColor: '#e8f5e9' }}>
              <TableCell>{unit.unitName}</TableCell>
              <TableCell align="right">{unit.oldFloorOrder === null ? 'N/A' : unit.oldFloorOrder}</TableCell>
              <TableCell align="right">{unit.newFloorOrder}</TableCell>
              <TableCell align="right">{unit.oldArea === null ? 'N/A' : unit.oldArea.toFixed(2)}</TableCell>
              <TableCell align="right">{unit.newArea.toFixed(2)}</TableCell>
              <TableCell>Added</TableCell>
            </TableRow>
          ))}
          {diffData.updated_units.map((unit) => (
            <TableRow key={unit.unitName} style={{ backgroundColor: '#fff3e0' }}>
              <TableCell>{unit.unitName}</TableCell>
              <TableCell align="right">{unit.oldFloorOrder === null ? 'N/A' : unit.oldFloorOrder}</TableCell>
              <TableCell align="right">{unit.newFloorOrder}</TableCell>
              <TableCell align="right">{unit.oldArea === null ? 'N/A' : unit.oldArea.toFixed(2)}</TableCell>
              <TableCell align="right">{unit.newArea.toFixed(2)}</TableCell>
              <TableCell>Updated</TableCell>
            </TableRow>
          ))}
          {diffData.same_units.map((unit) => (
            <TableRow key={unit.unitName} style={{ backgroundColor: '#f5f5f5' }}>
              <TableCell>{unit.unitName}</TableCell>
              <TableCell align="right">{unit.oldFloorOrder === null ? 'N/A' : unit.oldFloorOrder}</TableCell>
              <TableCell align="right">{unit.newFloorOrder}</TableCell>
              <TableCell align="right">{unit.oldArea === null ? 'N/A' : unit.oldArea.toFixed(2)}</TableCell>
              <TableCell align="right">{unit.newArea.toFixed(2)}</TableCell>
              <TableCell>Unchanged</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  );

  return (
    <Modal
      isOpen={isOpen}
      handleClose={handleClose}
      title="Yardi Import Changes"
      body={(
        <Box style={{ maxHeight: '80vh' }}>
          {renderFloorChanges()}
          {renderUnitChanges()}
        </Box>
      )}
      confirmText="Confirm Changes"
      confirmAction={confirmAction}
      isFullScreen
    />
  );
};

export default BuildingDiffModal;
