import { UseMutationResult, useMutation, useQueryClient } from 'react-query';
import {
  YardiFilesEntity, ParseYardiBuildingResponse, ParseYardiRentResponse, YardiUploadFiles,
} from './types';
import httpClient from '../../core/httpClient';

export const useUploadYardiFileMutation = ():
UseMutationResult<YardiFilesEntity, Error, YardiUploadFiles> => {
  const queryClient = useQueryClient();
  return useMutation<YardiFilesEntity, Error, YardiUploadFiles>(
    async (files: YardiUploadFiles) => {
      const formData = new FormData();
      formData.append('rentRoll', files.rentRoll);
      formData.append('buildingDataGrid', files.buildingDataGrid);
      formData.append('buildingId', files.buildingId.toString());
      const response = await httpClient.post('/yardi/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      return response.data;
    },
    {
      onSuccess: async (data) => {
        const prevData = queryClient.getQueryData<YardiFilesEntity[]>('yardi-files');
        if (prevData) {
          queryClient.setQueryData<YardiFilesEntity[]>('yardi-files', [data, ...prevData]);
        }
      },
    },
  );
};

export const useParseYardiFileMutation = ():
UseMutationResult<ParseYardiBuildingResponse | ParseYardiRentResponse, Error, { id: number, applyChanges: boolean }> => {
  const queryClient = useQueryClient();
  return useMutation<ParseYardiBuildingResponse | ParseYardiRentResponse, Error, { id: number, applyChanges: boolean }>(
    async ({ id, applyChanges }) => {
      const response = await httpClient.post(`/yardi/process/${id}`, { applyChanges });
      return response.data;
    },
    {
      onSuccess: async (data) => {
        queryClient.invalidateQueries('yardi-files');

        const prevData = queryClient.getQueryData<YardiFilesEntity[]>('yardi-files');
        if (prevData) {
          queryClient.setQueryData<YardiFilesEntity[]>(
            'yardi-files',
            prevData.map((item) => {
              if (item.id === data.id) {
                return data;
              }
              return item;
            }),
          );
        }
      },
    },
  );
};

export const useDeleteYardiFileMutation = ():
UseMutationResult<YardiFilesEntity, Error, number> => {
  const queryClient = useQueryClient();
  return useMutation<YardiFilesEntity, Error, number>(
    async (id: number) => {
      const response = await httpClient.delete(`/yardi/delete/${id}`);
      return response.data;
    },
    {
      onSuccess: async (data) => {
        const prevData = queryClient.getQueryData<YardiFilesEntity[]>('yardi-files');
        if (prevData) {
          queryClient.setQueryData<YardiFilesEntity[]>(
            'yardi-files',
            prevData.filter((item) => item.id !== data.id),
          );
        }
      },
    },
  );
};
