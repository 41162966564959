import React, {
  useEffect, useMemo, useState,
} from 'react';
import { MRT_ColumnDef, MRT_PaginationState, MaterialReactTable } from 'material-react-table';
import {
  Box, Button, IconButton, Select, Typography,
} from '@material-ui/core';
import {
  CheckCircleOutline, CloseOutlined, DeleteForeverOutlined, MemoryOutlined,
} from '@material-ui/icons';
import { useGetAllYardiFiles } from '../../../queries/yardi';
import { YardiFilesEntity, ParseYardiBuildingResponse, ParseYardiRentResponse } from '../../../queries/yardi/types';
import PageTitle from '../../ui/PageTitle';
import { useButtonsStyles } from '../../../styles/useButtonsStyles';
import Modal from '../../modals/Modal/Modal';
import {
  useDeleteYardiFileMutation, useParseYardiFileMutation,
  useUploadYardiFileMutation,
} from '../../../queries/yardi/mutations';
import useDeleteModal from '../../modals/DeleteModal/hooks';
import useConfirmModal from '../../modals/ConfirmModal/hooks';
import { formatDateWithoutTimeZoneDifference } from '../../../formatters/dateFormatters/dateFormatters';
import LoaderModal from '../../modals/LoaderModal/LoaderModal';
import BuildingDiffModal, { BuildingDiffData } from '../../modals/DiffModal/BuildingDiffModal';
import RentDiffModal, { RentDiffData } from '../../modals/DiffModal/RentDiffModal';
import { useGetAllBuildingsByStatusQuery } from '../../../queries/buildings';
import { BuildingStatus } from '../../../types/buildings';

const YardiIndex: React.FC = () => {
  const buttonClasses = useButtonsStyles();
  const [rowCount, setRowCount] = useState(0);
  const [openUploadModal, setOpenUploadModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [msg, setMsg] = useState('');
  const [rentRollFile, setRentRollFile] = useState<File | null>(null);
  const [buildingDataGridFile, setBuildingDataGridFile] = useState<File | null>(null);
  const [buildingDiffModalOpen, setBuildingDiffModalOpen] = useState(false);
  const [rentDiffModalOpen, setRentDiffModalOpen] = useState(false);
  const [buildingDiffData, setBuildingDiffData] = useState<BuildingDiffData | null>(null);
  const [rentDiffData, setRentDiffData] = useState<RentDiffData | null>(null);
  const [selectedFileId, setSelectedFileId] = useState<number | null>(null);
  const [errorModalOpen, setErrorModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [selectedBuildingId, setSelectedBuildingId] = useState<number>(-1);

  const {
    data: buildings, isLoading: isBuildingsLoading, isFetching: isBuildingsFetching,
  } = useGetAllBuildingsByStatusQuery(BuildingStatus.Active);

  const {
    showModal: showDeleteModal, RenderModal: DeleteModal, modalId: deleteModalId,
  } = useDeleteModal();

  const {
    showModal: showParseModal, RenderModal: ParseModal, modalId: parseModalId,
  } = useConfirmModal();

  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 5,
  });

  const { data: yardiFilesData, isLoading, isFetching } = useGetAllYardiFiles(
    !isBuildingsLoading && !isBuildingsFetching && !!buildings?.length,
  );

  const uploadFileMutation = useUploadYardiFileMutation();
  const parseYardiFileMutation = useParseYardiFileMutation();
  const deleteYardiFileMutation = useDeleteYardiFileMutation();

  useEffect(() => {
    if (yardiFilesData) {
      setRowCount(yardiFilesData.length);
    }
  }, [yardiFilesData]);

  useEffect(() => {
    if (buildings && buildings.length > 0) {
      setSelectedBuildingId(buildings[0].id);
    }
  }, [buildings]);

  const uploadYardiFile = () => {
    if (rentRollFile && buildingDataGridFile && selectedBuildingId !== -1) {
      setLoading(true);
      uploadFileMutation.mutateAsync({
        rentRoll: rentRollFile,
        buildingDataGrid: buildingDataGridFile,
        buildingId: selectedBuildingId,
      }).then(() => {
        setRentRollFile(null);
        setBuildingDataGridFile(null);
        setLoading(false);
      });
      setOpenUploadModal(false);
    } else {
      setErrorMessage('Please select a file and a building');
      setErrorModalOpen(true);
    }
  };

  // eslint-disable-next-line max-len, @typescript-eslint/no-explicit-any
  const isInstanceOfParseYardiRentResponse = (response: any): response is ParseYardiRentResponse => response.type === 'rentRoll';

  // eslint-disable-next-line max-len, @typescript-eslint/no-explicit-any
  const isInstanceOfParseYardiBuildingResponse = (response: any): response is ParseYardiBuildingResponse => response.type === 'buildingDataGrid';

  const parseYardiFile = async (id: number | null) => {
    if (id) {
      setSelectedFileId(id);
      setMsg('This process may take a few minutes. Do not close this page.');
      setLoading(true);
      try {
        const response = await parseYardiFileMutation.mutateAsync({ id, applyChanges: false });
        setLoading(false);
        setMsg('');
        if (Array.isArray(response) && response[0]?.error) {
          setErrorMessage(response[0].error);
          setErrorModalOpen(true);
          return;
        }
        if (isInstanceOfParseYardiBuildingResponse(response)) {
          const extractedDiffData: BuildingDiffData = {
            building_name: response.buildingName,
            building_area: response.buildingArea,
            type: response.type,
            new_floors: response.newFloors,
            updated_floors: response.updatedFloors,
            same_floors: response.sameFloors,
            new_units: response.newUnits,
            updated_units: response.updatedUnits,
            same_units: response.sameUnits,
          };
          setBuildingDiffData(extractedDiffData);
          setBuildingDiffModalOpen(true);
        } else if (isInstanceOfParseYardiRentResponse(response)) {
          const extractedDiffData: RentDiffData = {
            building_name: response.buildingName,
            type: response.type,
            newLeases: response.newLeases,
            updatedLeases: response.updatedLeases,
            sameLeases: response.sameLeases,
          };
          setRentDiffData(extractedDiffData);
          setRentDiffModalOpen(true);
        }
      } catch (error) {
        setLoading(false);
        setMsg('');
        setErrorMessage('An error occurred while parsing the file');
        setErrorModalOpen(true);
      }
    }
  };

  const proccessYardiFile = async (id: number | null) => {
    if (id) {
      setLoading(true);
      try {
        const response = await parseYardiFileMutation.mutateAsync({ id, applyChanges: true });
        setLoading(false);
        if (Array.isArray(response) && response[0]?.error) {
          setErrorMessage(response[0].error);
          setErrorModalOpen(true);
          return;
        }
        // Close the diff modals since processing was successful
        setBuildingDiffModalOpen(false);
        setRentDiffModalOpen(false);
      } catch (error) {
        setLoading(false);
        setErrorMessage('An error occurred while processing the file');
        setErrorModalOpen(true);
      }
    }
  };

  const handleDeleteFile = async (fileId: number | null) => {
    if (fileId) {
      setLoading(true);
      deleteYardiFileMutation.mutateAsync(fileId).then(() => {
        setLoading(false);
      });
    }
  };

  const handleBuildingChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSelectedBuildingId(event.target.value as number);
  };

  const columns = useMemo<MRT_ColumnDef<YardiFilesEntity>[]>(() => [
    {
      header: 'Uploaded Files',
      // eslint-disable-next-line react/display-name
      accessorFn: (row) => (
        <>
          <Typography
            style={{ cursor: 'pointer' }}
            noWrap
          >
            <Box>
              {row.scheduleDataName}
            </Box>
            <Box>
              {row.buildingDataName}
            </Box>
          </Typography>
        </>
      ),
    },
    {
      header: 'Building',
      size: 100,
      // eslint-disable-next-line react/display-name
      accessorFn: (row) => {
        if (isBuildingsLoading || isBuildingsFetching) {
          return (
            <Typography
              style={{ cursor: 'pointer' }}
              noWrap
            >
              Loading...
            </Typography>
          );
        }
        const building = buildings?.find((b) => b.id === row.buildingId);
        return (
          <Typography
            style={{ cursor: 'pointer' }}
            noWrap
          >
            {building?.name || 'Unknown Building'}
          </Typography>
        );
      },
    },
    {
      header: 'Uploaded',
      accessorKey: 'createdAt',
      size: 100,
      // eslint-disable-next-line react/display-name
      accessorFn: (row) => (
        <Typography
          style={{ cursor: 'pointer' }}
          noWrap
        >
          {(formatDateWithoutTimeZoneDifference(row?.createdAt))}
        </Typography>
      ),
    },
    {
      header: 'Area processed',
      accessorKey: 'buildingParsed',
      size: 50,
      // eslint-disable-next-line react/display-name
      accessorFn: (row) => (
        <>
          {row.buildingParsed ? (
            <CheckCircleOutline style={{ color: 'green' }} />
          ) : (
            <CloseOutlined style={{ color: 'red' }} />
          )}
        </>
      ),
    },
    {
      header: 'Tenancy processed',
      accessorKey: 'scheduleParsed',
      size: 50,
      // eslint-disable-next-line react/display-name
      accessorFn: (row) => (
        <>
          {row.scheduleParsed ? (
            <CheckCircleOutline style={{ color: 'green' }} />
          ) : (
            <CloseOutlined style={{ color: 'red' }} />
          )}
        </>
      ),
    },
    {
      header: 'Action',
      accessorKey: 'action',
      size: 50,
      // eslint-disable-next-line react/display-name
      accessorFn: (row) => (
        <>
          {/* check if row parse or not to add parse button */}
          {(!row.scheduleParsed || !row.buildingParsed) && (
            <IconButton
              style={{ width: '25px', marginRight: '10px' }}
              onClick={(e) => {
                e.stopPropagation();
                showParseModal(row.id);
              }}
              aria-label="parse"
            >
              <MemoryOutlined />
            </IconButton>
          )}
          <IconButton
            style={{ width: '25px' }}
            onClick={(e) => {
              e.stopPropagation();
              showDeleteModal(row.id);
            }}
            aria-label="delete"
          >
            <DeleteForeverOutlined />
          </IconButton>
        </>
      ),
    },
  ], [buildings, isBuildingsLoading, isBuildingsFetching]);

  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="flex-end" mb={3}>
        <PageTitle>Yardi</PageTitle>
        <Button
          className={buttonClasses.large}
          variant="contained"
          color="default"
          onClick={() => {
            if (!isBuildingsLoading && !isBuildingsFetching) setOpenUploadModal(true);
          }}
        >
          Upload Yardi Files
        </Button>
      </Box>
      <Box style={{ height: '100%' }}>
        <MaterialReactTable
          columns={columns}
          data={yardiFilesData || []}
          enableTopToolbar={false}
          enableColumnResizing
          enableColumnActions={false}
          enableFullScreenToggle={false}
          enableRowOrdering={false}
          enableSorting={false}
          enableGlobalFilterModes={false}
          enableDensityToggle={false}
          state={{ pagination, isLoading: isLoading || isFetching }}
          rowCount={rowCount}
          onPaginationChange={setPagination}
          layoutMode="grid"
          muiTableProps={{
            sx: {
              tableLayout: 'fixed',
              display: 'table',
            },
          }}
        />
      </Box>
      <Modal
        confirmColor="secondary"
        title={
          (
            <Box style={{ display: 'flex' }}>
              <Box>Upload Yardi Files</Box>
            </Box>
          )
        }
        body={(
          <Box style={{ overflowY: 'hidden' }}>
            <Typography variant="subtitle1">Select Building</Typography>
            <Select
              style={{ marginBottom: '20px' }}
              native
              value={selectedBuildingId}
              onChange={handleBuildingChange}
              label="Filter by Building Status"
              inputProps={{
                name: 'building-status',
                id: 'building-status',
              }}
            >
              {buildings?.map((building) => (
                <option key={building.id} value={building.id}>
                  {building.name}
                </option>
              ))}
            </Select>
            <Box mb={2}>
              <Typography variant="subtitle1">Tenancy Schedule</Typography>
              <input
                type="file"
                accept=".xlsx, .xls"
                onChange={(e) => {
                  if (e.target.files) {
                    setRentRollFile(e.target.files[0]);
                  }
                }}
              />
            </Box>
            <Box>
              <Typography variant="subtitle1">Area Measurment</Typography>
              <input
                type="file"
                accept=".xlsx, .xls"
                onChange={(e) => {
                  if (e.target.files) {
                    setBuildingDataGridFile(e.target.files[0]);
                  }
                }}
              />
            </Box>
          </Box>
        )}
        isOpen={openUploadModal}
        handleClose={() => { setOpenUploadModal(false); }}
        confirmText="Upload"
        confirmAction={uploadYardiFile}
        cancelText="Cancel"
        cancelAction={() => { setOpenUploadModal(false); }}
      />
      <DeleteModal
        title="Delete Yardi File"
        body="Are you sure you want to delete this file?"
        confirmAction={() => handleDeleteFile(deleteModalId)}
      />
      <ParseModal
        title="Proccess Yardi File"
        body="Are you sure you want to proccess this file?"
        confirmAction={() => parseYardiFile(parseModalId)}
      />
      {buildingDiffData && (
        <BuildingDiffModal
          isOpen={buildingDiffModalOpen}
          handleClose={() => setBuildingDiffModalOpen(false)}
          confirmAction={() => proccessYardiFile(selectedFileId)}
          diffData={buildingDiffData}
        />
      )}
      <LoaderModal isOpen={loading} msg={msg} />
      {rentDiffData && (
        <RentDiffModal
          isOpen={rentDiffModalOpen}
          handleClose={() => setRentDiffModalOpen(false)}
          confirmAction={() => proccessYardiFile(selectedFileId)}
          diffData={rentDiffData}
        />
      )}
      <Modal
        isOpen={errorModalOpen}
        handleClose={() => setErrorModalOpen(false)}
        title="Error"
        body={errorMessage}
        confirmText="OK"
        confirmAction={() => setErrorModalOpen(false)}
      />
    </>
  );
};

export default YardiIndex;
