import React from 'react';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import Modal from '../Modal/Modal';
import { ModalProps } from '../interfaces';

interface UnitLeaseDiff {
  type: string;
  unitName: string;
  floor: string;
  oldMonthlyRent: number;
  newMonthlyRent: number;
  oldArea: number;
  newArea: number;
}

interface LeaseDiff {
  leaseCode: string;
  tenantName: string;
  oldLeaseType: string;
  newLeaseType: string;
  oldLeaseStart: Date;
  newLeaseStart: Date;
  oldLeaseEnd: Date;
  newLeaseEnd: Date;
  oldTerm: number;
  newTerm: number;
  oldArea: number;
  newArea: number;
  isMtmOld: boolean;
  isMtmNew: boolean;

  spaces: Array<UnitLeaseDiff>;
}

export interface RentDiffData {
  building_name: string;
  type: string;
  newLeases: Array<LeaseDiff>;
  updatedLeases: Array<LeaseDiff>;
  sameLeases: Array<LeaseDiff>;
}

interface RentDiffModalProps extends Omit<ModalProps, 'body'> {
  diffData: RentDiffData;
}

const RentDiffModal = ({
  isOpen,
  handleClose,
  confirmAction,
  diffData,
}: RentDiffModalProps): JSX.Element => {
  const getDiffColor = (oldValue: unknown, newValue: unknown, status: string) => {
    if (status === 'Added') {
      return '#baffc6';
    }

    return oldValue !== newValue ? '#fac5c5' : '#baffc6';
  };

  const getDateDiffColor = (oldDate: Date | null, newDate: Date | null, status: string) => {
    const oldStr = oldDate?.toString() || '';
    const newStr = newDate?.toString() || '';
    return getDiffColor(oldStr, newStr, status);
  };

  const renderSpaceRow = (space: UnitLeaseDiff, status: string) => {
    const spaceRowStyle = {
      backgroundColor: status === 'Added'
        ? '#f5f9f5'
        : status === 'Updated'
          ? '#fff9f0'
          : '#fafafa',
      height: '40px',
    };

    return (
      <TableRow key={`${space.unitName}-space`} style={spaceRowStyle}>
        <TableCell colSpan={2} />
        <TableCell>{space.unitName}</TableCell>
        <TableCell>{space.floor}</TableCell>
        <TableCell colSpan={4} />
        <TableCell
          style={{
            backgroundColor: getDiffColor(space.oldMonthlyRent, space.newMonthlyRent, status),
            borderRight: '1px solid rgba(224, 224, 224, 1)',
          }}
          align="right"
        >
          {space.oldMonthlyRent !== null ? space.oldMonthlyRent.toFixed(2) : 'N/A'}
        </TableCell>
        <TableCell
          style={{ backgroundColor: getDiffColor(space.oldMonthlyRent, space.newMonthlyRent, status) }}
          align="right"
        >
          {space.newMonthlyRent !== null ? space.newMonthlyRent.toFixed(2) : 'N/A'}
        </TableCell>
        <TableCell colSpan={2} />
        <TableCell style={{
          backgroundColor: getDiffColor(space.oldArea, space.newArea, status),
          borderRight: '1px solid rgba(224, 224, 224, 1)',
        }}
        >
          {space.oldArea || 'N/A'}
        </TableCell>
        <TableCell style={{ backgroundColor: getDiffColor(space.oldArea, space.newArea, status) }}>
          {space.newArea || 'N/A'}
        </TableCell>
        <TableCell colSpan={2} />
        <TableCell>{space.type}</TableCell>
      </TableRow>
    );
  };

  const renderLeaseRow = (lease: LeaseDiff, status: string) => {
    const rowStyle = {
      backgroundColor: status === 'Added'
        ? '#e8f5e9'
        : status === 'Updated'
          ? '#fff3e0'
          : '#f5f5f5',
      height: '48px',
    };

    return (
      <React.Fragment key={`${lease.leaseCode}-lease`}>
        <TableRow style={rowStyle}>
          <TableCell>{lease.leaseCode}</TableCell>
          <TableCell>{lease.tenantName}</TableCell>
          <TableCell colSpan={2} />
          <TableCell style={{
            backgroundColor: getDateDiffColor(lease.oldLeaseStart, lease.newLeaseStart, status),
            borderRight: '1px solid rgba(224, 224, 224, 1)',
          }}
          >
            {lease.oldLeaseStart ? lease.oldLeaseStart.toString() : 'N/A'}
          </TableCell>
          <TableCell style={{ backgroundColor: getDateDiffColor(lease.oldLeaseStart, lease.newLeaseStart, status) }}>
            {lease.newLeaseStart ? lease.newLeaseStart.toString() : 'N/A'}
          </TableCell>
          <TableCell style={{
            backgroundColor: getDateDiffColor(lease.oldLeaseEnd, lease.newLeaseEnd, status),
            borderRight: '1px solid rgba(224, 224, 224, 1)',
          }}
          >
            {lease.oldLeaseEnd ? lease.oldLeaseEnd.toString() : 'N/A'}
          </TableCell>
          <TableCell style={{ backgroundColor: getDateDiffColor(lease.oldLeaseEnd, lease.newLeaseEnd, status) }}>
            {lease.newLeaseEnd ? lease.newLeaseEnd.toString() : 'N/A'}
          </TableCell>
          <TableCell colSpan={2} />
          <TableCell
            style={{
              backgroundColor: getDiffColor(lease.oldTerm, lease.newTerm, status),
              borderRight: '1px solid rgba(224, 224, 224, 1)',
            }}
            align="right"
          >
            {lease.oldTerm || 'N/A'}
          </TableCell>
          <TableCell
            style={{ backgroundColor: getDiffColor(lease.oldTerm, lease.newTerm, status) }}
            align="right"
          >
            {lease.newTerm || 'N/A'}
          </TableCell>
          <TableCell colSpan={2} />
          <TableCell style={{
            backgroundColor: getDiffColor(lease.isMtmOld, lease.isMtmNew, status),
            borderRight: '1px solid rgba(224, 224, 224, 1)',
          }}
          >
            {lease.isMtmOld ? 'Yes' : 'No'}
          </TableCell>
          <TableCell style={{ backgroundColor: getDiffColor(lease.isMtmOld, lease.isMtmNew, status) }}>
            {lease.isMtmNew ? 'Yes' : 'No'}
          </TableCell>
          <TableCell>{status}</TableCell>
        </TableRow>
        {lease.spaces.length > 0 && lease.spaces.map((space) => renderSpaceRow(space, status))}
      </React.Fragment>
    );
  };

  const renderLeaseChanges = () => (
    <Box mb={3}>
      <Typography variant="h5" gutterBottom>
        <strong>{diffData.building_name}</strong>
      </Typography>
      <Typography variant="h6" gutterBottom>Lease Changes</Typography>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell style={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>Lease</TableCell>
            <TableCell style={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>Tenant</TableCell>
            <TableCell style={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>Space Name</TableCell>
            <TableCell style={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>Floor</TableCell>
            <TableCell>Old Lease Start</TableCell>
            <TableCell style={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>New Lease Start</TableCell>
            <TableCell>Old Lease End</TableCell>
            <TableCell style={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>New Lease End</TableCell>
            <TableCell align="right">Old Monthly Rent</TableCell>
            <TableCell align="right" style={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
              New Monthly Rent
            </TableCell>
            <TableCell align="right">Old Term</TableCell>
            <TableCell align="right" style={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>New Term</TableCell>
            <TableCell>Old Area</TableCell>
            <TableCell style={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>New Area</TableCell>
            <TableCell>Old MTM</TableCell>
            <TableCell style={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>New MTM</TableCell>
            <TableCell>Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {diffData.newLeases.map((lease) => renderLeaseRow(lease, 'Added'))}
          {diffData.updatedLeases.map((lease) => renderLeaseRow(lease, 'Updated'))}
          {diffData.sameLeases.map((lease) => renderLeaseRow(lease, 'Unchanged'))}
        </TableBody>
      </Table>
    </Box>
  );

  return (
    <Modal
      isOpen={isOpen}
      handleClose={handleClose}
      title="Yardi Import Changes"
      body={(
        <Box style={{ maxHeight: '80vh' }}>
          {renderLeaseChanges()}
        </Box>
      )}
      confirmText="Confirm Changes"
      confirmAction={confirmAction}
      isFullScreen
    />
  );
};

export default RentDiffModal;
